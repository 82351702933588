/* Primary Color Codes */
/* blue- #3c3e95 */
/* red- #e23038 */

.navbar {
  width: 100%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 1000;
}

.topBar {
  height: 10px;
  background-color: #f8f9fa;
  padding: 16px 2rem;
  display: flex;
  justify-content: space-between;
}

.container {
  display: flex;
  justify-content: space-between;
  padding: 10px 2rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #efe4e4;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.jd,
.locationText {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.jd:hover,
.location-container:hover .locationText {
  color: #3c3e95;
}

.navItem {
  color: #333;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
  margin: 10px 0;
}

.navItem:hover {
  background-color: #e2e6ea;
  color: #3c3e95;
}

.location-container {
  display: flex;
  gap: 2px;
  align-items: center;
  transition: color 0.3s ease-in-out;
}

.location-container svg {
  transition: fill 0.3s ease-in-out;
}

.location-container:hover svg {
  fill: #3c3e95;
}

.titlered,
.titleBlack,
.titleBlue {
  line-height: 1.2;
  font-family: "Tiro Devanagari Marathi";
  display: inline-block;
}

.titlered {
  background: linear-gradient(to bottom, #e12024, #600c0a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px; /* Reduced size */
  font-weight: 700;
  padding: 14px 0 0 0;
}

.titleBlack {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.titleBlue {
  background: linear-gradient(to bottom, #7e82df, #11155d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px; /* Reduced size */
  font-weight: 900;
  margin: 0;
  padding: 4px 0 0 0;
}

.navbar p {
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

.hamburger {
  display: none;
  cursor: pointer;
  font-size: 30px;
}

.hamburgerButton,
.closeButton {
  font-size: 30px;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100%;
  width: 250px;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 20px;
  transition: left 0.3s ease-in-out;
}

.sidebar.open {
  left: 0;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .navItem {
    display: block;
    padding: 10px;
    font-size: 18px;
    color: #333;
  }

  .container .navItem {
    display: none; /* Hide navbar items in the container on mobile view */
  }
  .titlered,
  .titleBlack {
    /* display: none; */
    font-size: 12px; ;
  }
  .titleBlue{
    font-size: 20px;
  }
}

@media (min-width: 769px) {
  .sidebar {
    display: none; /* Hide the sidebar on larger screens */
  }
}
