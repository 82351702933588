/* home.module.css */

/* Initial state of the container before it loads */
.container {
  opacity: 0;
  transform: translateY(0);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* Class to be added once the component has mounted */
.containerLoaded {
  opacity: 1;
  transform: translateY(0);
}
