.App {
  text-align: center;
}

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%; /* Ensure full height is covered */
  background: #f8f9fa;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  flex: 1;
}
