.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  color: #333;
  max-width: 1540px;
  margin: auto;
}

.titleBox {
  font-size: 2rem;
  text-align: center;   
  padding: 2rem 1rem;
  border-radius: 10px;
  width: 100%;
  background: linear-gradient(to top, #8486f6, rgba(60, 62, 149, 0));
}

