.topBar {
  height: 10px;
  background-color: #f8f9fa;
  padding: 1.25rem 2rem;
  display: flex;
  justify-content: space-between;
  background-color: #f8f9fa;
  border-bottom: 1px solid #efe4e4;
}

.jd {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  cursor: pointer;
}

.jd:hover {
  color: #1c7dd2;
  transition: color 0.3s ease-in-out;
}

.navItem {
  color: #333;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.navItem:hover {
  background-color: #e2e6ea;
  color: #1c7dd2;
}

.locationText {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  cursor: pointer;
}

.location-container {
  display: flex;
  gap: 2px;
  align-items: center;
  transition: color 0.3s ease-in-out;
}

.location-container svg,
.location-container .locationText {
  transition: fill 0.3s ease-in-out, color 0.3s ease-in-out;
}

.location-container:hover svg {
  fill: #1c7dd2;
}

.location-container:hover .locationText {
  color: #1c7dd2;
}
