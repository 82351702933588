.footer {
  width: 100%;
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  gap: 1rem;
  color: #333;
  border-top: 1px solid #efe4e4;
  padding: 2rem 10rem;
}

.icon {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 2rem;
    flex-direction: column;
    gap: 2rem;
  }
}
